import { createBrowserRouter, Navigate } from 'react-router-dom'

import SuperAdminLayout from '../../layout/root/SuperAdminLayout'


import Suspense from '../zSuspense/Suspense'
import {
    UserAccount,
    Dashboard, PageNotFound, TermsAndConditions,
    Examiners, CustomizeExaminer,
    Exams, CustomizeExam, Syllabus, CustomizeSyllabus,
    Questions, CustomizeQuestion, EasyUpload, BulkUpload,
    AllUsers, CustomizeUser, CurrentAffairs, CustomizeCurrentAffair,
    Vacancies, CustomizeVacancy, Quiz, ProductManagement, TestSeries,
    CustomizeTestSeries, CreateTestPaper, TestPapers,
} from './components/routeLazyLoading'


// <----- Relative Path ----->
const SuperAdminRoutes = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/superAdmin/dashboard" replace />, // Step 01 ::: Redirect Root path '/' To '/superAdmin/dashboard'
        errorElement: <Suspense><PageNotFound /></Suspense>,
    },
    {
        path: 'superAdmin',
        element: <SuperAdminLayout />,
        errorElement: <Suspense><PageNotFound /></Suspense>,
        children: [
            {
                element: <Navigate to="/superAdmin/dashboard" replace />,
                index: true, // Step 02 ::: Now When Path Is '/superAdmin' Redirect To '/superAdmin/dashboard'
            },
            // Dashboard :::
            { path: 'dashboard', element: <Suspense><Dashboard /></Suspense> },
            // Current User Account :::
            { path: 'user/account', element: <Suspense><UserAccount /></Suspense> },
            // All Users :::
            { path: 'controlPanel/users', element: <Suspense><AllUsers /></Suspense> },
            { path: 'controlPanel/user/create', element: <Suspense><CustomizeUser /></Suspense> },
            { path: 'controlPanel/user/update/:id', element: <Suspense><CustomizeUser /></Suspense> },
            // Master :::
            { path: 'master/examiners', element: <Suspense><Examiners /></Suspense> },
            { path: 'master/examiner/create', element: <Suspense><CustomizeExaminer /></Suspense> },
            { path: 'master/examiner/update/:id', element: <Suspense><CustomizeExaminer /></Suspense> },
            { path: 'master/exams', element: <Suspense><Exams /></Suspense> },
            { path: 'master/exam/create', element: <Suspense><CustomizeExam /></Suspense> },
            { path: 'master/exam/update/:id', element: <Suspense><CustomizeExam /></Suspense> },
            { path: 'master/syllabus', element: <Suspense><Syllabus /></Suspense> },
            { path: 'master/syllabus/create', element: <Suspense><CustomizeSyllabus /></Suspense> },
            { path: 'master/syllabus/update/:id', element: <Suspense><CustomizeSyllabus /></Suspense> },
            // Questions :::
            { path: 'questions/questionCollection', element: <Suspense><Questions /></Suspense> },
            { path: 'question/create', element: <Suspense><CustomizeQuestion /></Suspense> },
            { path: 'question/update/:id', element: <Suspense><CustomizeQuestion /></Suspense> },
            { path: 'questions/easyUpload', element: <Suspense><EasyUpload /></Suspense> },
            { path: 'questions/bulkUpload', element: <Suspense><BulkUpload /></Suspense> },
            // Bolgs :::
            { path: 'web/currentAffairs', element: <Suspense><CurrentAffairs /></Suspense> },
            { path: 'web/currentAffair/create', element: <Suspense><CustomizeCurrentAffair /></Suspense> },
            { path: 'web/currentAffair/update/:id', element: <Suspense><CustomizeCurrentAffair /></Suspense> },
            { path: 'web/vacancies', element: <Suspense><Vacancies /></Suspense> },
            { path: 'web/vacancy/create', element: <Suspense><CustomizeVacancy /></Suspense> },
            { path: 'web/vacancy/update/:id', element: <Suspense><CustomizeVacancy /></Suspense> },
            // Quiz :::
            { path: 'web/quiz', element: <Suspense><Quiz /></Suspense> },
            // Product Management :::
            { path: 'web/productManagement', element: <Suspense><ProductManagement /></Suspense> },
            // Test Series :::
            { path: 'test/testSeries', element: <Suspense><TestSeries /></Suspense> },
            { path: 'test/testSeries/create', element: <Suspense><CustomizeTestSeries /></Suspense> },
            { path: 'test/testSeries/update/:id', element: <Suspense><CustomizeTestSeries /></Suspense> },
            { path: 'test/createTestPaper', element: <Suspense><CreateTestPaper /></Suspense> },
            { path: 'test/testSeries/testPapers/:testSeriesID', element: <Suspense><TestPapers /></Suspense> },
            // Terms & Conditions :::
            { path: 'termsConditions', element: <Suspense><TermsAndConditions /></Suspense> }
        ],
    }
])

export default SuperAdminRoutes;

// <----- Absolute Path ----->

// const SuperAdminRoutes = createBrowserRouter([
//     {
//         path: '/',
//         element: <SuperAdminLayout />,
//         errorElement: <Suspense><PageNotFound /></Suspense>,
//         children: [
//             // Dashboard :::
//             { path: '/', element: <Suspense><Dashboard /></Suspense> },
//             // Current User Account :::
//             { path: '/user/account', element: <Suspense><UserAccount /></Suspense> },
//             // All Users :::
//             { path: '/superAdmin/users', element: <Suspense><AllUsers /></Suspense> },
//             { path: '/superAdmin/user/create', element: <Suspense><CustomizeUser /></Suspense> },
//             { path: '/superAdmin/user/update/:id', element: <Suspense><CustomizeUser /></Suspense> },
//             // Master :::
//             { path: '/master/examiners', element: <Suspense><Examiners /></Suspense> },
//             { path: '/master/examiner/create', element: <Suspense><CustomizeExaminer /></Suspense> },
//             { path: '/master/examiner/update/:id', element: <Suspense><CustomizeExaminer /></Suspense> },
//             { path: '/master/exams', element: <Suspense><Exams /></Suspense> },
//             { path: '/master/exam/create', element: <Suspense><CustomizeExam /></Suspense> },
//             { path: '/master/exam/update/:id', element: <Suspense><CustomizeExam /></Suspense> },
//             { path: '/master/syllabus', element: <Suspense><Syllabus /></Suspense> },
//             { path: '/master/syllabus/create', element: <Suspense><CustomizeSyllabus /></Suspense> },
//             { path: '/master/syllabus/update/:id', element: <Suspense><CustomizeSyllabus /></Suspense> },
//             // Questions :::
//             { path: '/questions/questionCollection', element: <Suspense><Questions /></Suspense> },
//             { path: '/question/create', element: <Suspense><CustomizeQuestion /></Suspense> },
//             { path: '/question/update/:id', element: <Suspense><CustomizeQuestion /></Suspense> },
//             { path: '/questions/easyUpload', element: <Suspense><EasyUpload /></Suspense> },
//             { path: '/questions/bulkUpload', element: <Suspense><BulkUpload /></Suspense> },
//             // Bolgs :::
//             { path: '/currentAffairs', element: <Suspense><CurrentAffairs /></Suspense> },
//             { path: '/currentAffair/create', element: <Suspense><CustomizeCurrentAffair /></Suspense> },
//             { path: '/currentAffair/update/:id', element: <Suspense><CustomizeCurrentAffair /></Suspense> },
//             { path: '/vacancies', element: <Suspense><Vacancies /></Suspense> },
//             { path: '/vacancy/create', element: <Suspense><CustomizeVacancy /></Suspense> },
//             { path: '/vacancy/update/:id', element: <Suspense><CustomizeVacancy /></Suspense> },
//             // Quiz :::
//             { path: '/quiz', element: <Suspense><Quiz /></Suspense> },
//             // Product Management :::
//             { path: '/productManagement', element: <Suspense><ProductManagement /></Suspense> },
//             // Terms & Conditions :::
//             { path: '/termsConditions', element: <Suspense><TermsAndConditions /></Suspense> }
//         ],
//     }
// ])
