
interface UserDetails { userType: string }
type panelRoot = '/superAdmin' | '/admin' | '/user'

export const path = (root: panelRoot, sublink: string) => {
    return `/${root}${sublink}`;
}

// Step 01 ::: Getting User Type i.e. superAdmin || admin || user
const userDetails = localStorage.getItem("userDetails");
const currentPanel = userDetails ? (JSON.parse(userDetails) as UserDetails).userType : null;

// Step 02 ::: Setting User Type As Control Panel Root Route
const controlPanelRoot = currentPanel as panelRoot;


export const ControlPanelPaths = {
    Root: controlPanelRoot,
    // Dashboard :::
    Dashboard: path(controlPanelRoot, '/dashboard'),
    // User Operations :::
    UserAccount: path(controlPanelRoot, '/user/account'),
    UserList: path(controlPanelRoot, '/controlPanel/users'),
    UserCreation: path(controlPanelRoot, '/controlPanel/user/create'),
    UserUpdation: path(controlPanelRoot, '/controlPanel/user/update/'),
    // Master :::
    ExaminerList: path(controlPanelRoot, '/master/examiners'),
    ExaminerCreation: path(controlPanelRoot, '/master/examiner/create'),
    ExaminerUpdation: path(controlPanelRoot, '/master/examiner/update/'),
    ExamList: path(controlPanelRoot, '/master/exams'),
    ExamCreation: path(controlPanelRoot, '/master/exam/create'),
    ExamUpdation: path(controlPanelRoot, '/master/exam/update/'),
    SyllabusList: path(controlPanelRoot, '/master/syllabus'),
    SyllabusCreation: path(controlPanelRoot, '/master/syllabus/create'),
    SyllabusUpdation: path(controlPanelRoot, '/master/syllabus/update/'),
    // Question :::
    QuestionList: path(controlPanelRoot, '/questions/questionCollection'),
    QuestionCreation: path(controlPanelRoot, '/question/create'),
    QuestionUpdation: path(controlPanelRoot, '/question/update/'),
    // Test Series :::
    TestSeriesList: path(controlPanelRoot, '/test/testSeries'),
    TestPaperCreation: path(controlPanelRoot, '/test/createTestPaper'),
    TestSeriesCreation: path(controlPanelRoot, '/test/testSeries/create'),
    TestSeriesUpdation: path(controlPanelRoot, '/test/testSeries/update/'),
    TestPaperList: path(controlPanelRoot, '/test/testSeries/testPapers/'),
    // Web Visibility :::
    QuizList: path(controlPanelRoot, '/web/quiz'),
    VacancyList: path(controlPanelRoot, '/web/vacancies'),
    VacancyCreation: path(controlPanelRoot, '/web/vacancy/create'),
    VacancyUpdation: path(controlPanelRoot, '/web/vacancy/update/'),
    CurrentAffairList: path(controlPanelRoot, '/web/currentAffairs'),
    CurrentAffairCreation: path(controlPanelRoot, '/web/currentAffair/create'),
    CurrentAffairUpdation: path(controlPanelRoot, '/web/currentAffair/update/'),
    // Product Management :::
    ProductList: path(controlPanelRoot, '/web/productManagement'),
    // Terms Conditions :::
    TermsCondition: path(controlPanelRoot, '/termsConditions')
};
