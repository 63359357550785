import { createSlice } from '@reduxjs/toolkit'
import { AllTestSeries, TestSeriesStructure } from './interface'

import {
    createTestSeriesAsync, deleteTestSeriesAsync, getAllTestSeriesAsync,
    getTestSeriesByIDAsync, testSeriesStatusUpdateAsync, updateTestSeriesAsync,
} from './testSeriesAsync'

interface TestSeriesSliceStates {
    loading: boolean
    statusLoading: boolean
    deleteLoading: boolean
    updateLoading: boolean
    createTestSeries: { code: number, message: string } | null
    allTestSeries: AllTestSeries | null
    testSeriesByID: TestSeriesStructure | null
    updateTestSeries: { code: number, message: string } | null
    deleteTestSeries: { code: number, message: string } | null
    updateStatus: { code: number, message: string } | null
}

const initialState: TestSeriesSliceStates = {
    loading: false,
    statusLoading: false,
    deleteLoading: false,
    updateLoading: false,
    createTestSeries: null,
    allTestSeries: null,
    testSeriesByID: null,
    updateTestSeries: null,
    deleteTestSeries: null,
    updateStatus: null
}

const testSeriesSlice = createSlice({
    initialState,
    name: "testSeriesSlice",
    extraReducers: (builder) => {
        builder.addCase((createTestSeriesAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((createTestSeriesAsync.fulfilled), (state, action) => {
            state.loading = false
            state.createTestSeries = action.payload
        })
        builder.addCase((createTestSeriesAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addCase((getAllTestSeriesAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((getAllTestSeriesAsync.fulfilled), (state, action) => {
            state.loading = false
            state.allTestSeries = action.payload
        })
        builder.addCase((getAllTestSeriesAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addCase((getTestSeriesByIDAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((getTestSeriesByIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.testSeriesByID = action.payload?.data
        })
        builder.addCase((getTestSeriesByIDAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addCase((updateTestSeriesAsync.pending), (state, action) => {
            state.updateLoading = true
        })
        builder.addCase((updateTestSeriesAsync.fulfilled), (state, action) => {
            state.updateLoading = false
            state.updateTestSeries = action.payload
        })
        builder.addCase((updateTestSeriesAsync.rejected), (state, action) => {
            state.updateLoading = false
        })
        builder.addCase((deleteTestSeriesAsync.pending), (state, action) => {
            state.deleteLoading = true
        })
        builder.addCase((deleteTestSeriesAsync.fulfilled), (state, action) => {
            state.deleteLoading = false
            state.deleteTestSeries = action.payload
        })
        builder.addCase((deleteTestSeriesAsync.rejected), (state, action) => {
            state.deleteLoading = false
        })
        builder.addCase((testSeriesStatusUpdateAsync.pending), (state, action) => {
            state.statusLoading = true
        })
        builder.addCase((testSeriesStatusUpdateAsync.fulfilled), (state, action) => {
            state.statusLoading = false
            state.updateStatus = action.payload
        })
        builder.addCase((testSeriesStatusUpdateAsync.rejected), (state, action) => {
            state.statusLoading = false
        })


    },
    reducers: {
        emptyTestSeries: () => initialState,
        emptyCurrentStatus: (state, action) => { state.updateStatus = null },
        emptyDeleteTestSeries: (state, action) => { state.deleteTestSeries = null },
    }
})

export const { emptyCurrentStatus, emptyDeleteTestSeries, emptyTestSeries } = testSeriesSlice.actions;
export default testSeriesSlice.reducer;